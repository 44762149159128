import React, { useEffect, useRef } from "react";
import About from "./about/About";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import "./Main.scss";
import Projects from "./projects/Projects";
import locomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";

const Main = () => {
  const scrollRef = useRef();
  useEffect(() => {
    const scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smartphone: { smooth: true },
      tablet: { smooth: true },
      lerp: 0.03,
      multiplier: 1.2,
    });
  });
  return (
    <div className="main" ref={scrollRef} data-scroll-container>
      <Home  />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;
