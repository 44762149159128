import React from "react";
import "./Language.scss";

const Language = () => {
  return (
    <section className="language">
      <div className="language-container">
        <p
          className="language-title"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="1"
        >
          Languages
        </p>
        <div className="language-content">
          <p data-scroll data-scroll-speed="3">Persian</p>
          <p data-scroll data-scroll-speed="3">English</p>
          <p data-scroll data-scroll-speed="3">Italian</p>
        </div>
      </div>
    </section>
  );
};

export default Language;
