import React from "react";
import MagneticButton from "../magnetic-button/MagneticButton";
import {Link} from 'react-router-dom'
import "./About.scss";

const About = () => {
  return (
    <section className="about">
      <p
        className="about-title"
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="-1"
      >
        about
      </p>
      <div className="about-container">
        <div className="about-content">
          <p className="about-content-text" data-scroll data-scroll-speed="1">
            I am a creative, driven front-end web developer with a talent for
            quickly developing elegant solutions to problems. I have a drive and
            spirit for excellence. I am skilled in writing clean, testable code.
            Proficient in mobile-first responsive design and test-driven
            development.
          </p>

          <div className="button-container" data-scroll data-scroll-speed="2">
            <Link to='/about-me'>
              <MagneticButton
                className="button-1"
                style={{ backgroundColor: "transparent" }}
                scale={2}
                tollerance={0.8}
                speed={0.3}
                borderRadius="50%"
                onClick={() => {
                  console.log("click");
                }}
              >
                <MagneticButton
                  className="button-1"
                  scale={4}
                  tollerance={1}
                  speed={0.5}
                  borderRadius="50%"
                  onClick={() => {
                    console.log("click");
                  }}
                >
                  More About Me
                </MagneticButton>
              </MagneticButton>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
