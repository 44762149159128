import React, { useContext } from "react";
import { CursorContext } from "../../context/cursorContext";
import "./Projects.scss";

const Projects = () => {
  const { setCursor } = useContext(CursorContext);

  return (
    <section className="projects">
      <div className="projects-container">
        <div className="projects-content">
          <a
            href="https://farzinmoha.github.io/resturant-sample/"
            target="_blank"
            rel="noreferrer"
            onMouseEnter={() => setCursor("pro1")}
            onMouseLeave={() => setCursor("default")}
            className="project"
            onClick={() => setCursor("default")}
          >
            NEW BRIDGE SPRIZZA
          </a>
          <a
            href="https://farzinmoha.github.io/webster/"
            target="_blank"
            rel="noreferrer"
            className="project"
            onMouseEnter={() => setCursor("pro2")}
            onMouseLeave={() => setCursor("default")}
            onClick={() => setCursor("default")}
          >
            WEBSTER
          </a>
          <a
            href="https://farzinmoha.github.io/amir-website/"
            target="_blank"
            rel="noreferrer"
            className="project"
            onMouseEnter={() => setCursor("pro3")}
            onMouseLeave={() => setCursor("default")}
            onClick={() => setCursor("default")}
          >
            AMIR PRO NODE
          </a>
          <a
            href="https://farzinmoha.github.io/my-first-portfolio/"
            target="_blank"
            rel="noreferrer"
            className="project"
            onMouseEnter={() => setCursor("pro5")}
            onMouseLeave={() => setCursor("default")}
            onClick={() => setCursor("default")}
          >
            FARZIN
          </a>
        </div>
      </div>
      <div className="circle" data-scroll data-scroll-speed="5"></div>
      <p
        className="projects-title"
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="1"
      >
        projects
      </p>
    </section>
  );
};

export default Projects;
