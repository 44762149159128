import React, { useContext } from "react";
import { CursorContext } from "../../../context/cursorContext";
import "./Education.scss";

const Education = () => {
  const { setCursor } = useContext(CursorContext);
  return (
    <section className="education">
      <div className="education-container">
        <p
          className="education-title"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-1"
        >
          Education
        </p>
        <div className="education-content">
          <div className="education-content-text">
            <div
              className="education-content-text-row"
              data-scroll
              data-scroll-speed="1.5"
            >
              <p className="education-content-text1">⚪️ Bachelor of PHYSICS</p>
              <p className="education-content-text2">
                Azad University of Tehran, North Tehran Branch
              </p>
              <p className="education-content-text3">
                Year of Graduation: 2014
              </p>
            </div>
            <div
              className="education-content-text-row"
              data-scroll
              data-scroll-speed="1.3"
            >
              <p className="education-content-text1">
                ⚪️ Complete React Developer (w/ Redux, Hooks, GraphQL)
              </p>
              <p className="education-content-text2">Udemy</p>
              <p className="education-content-text3">
                Instructors: Andrei Neagoie, Yihua Zhang, Zero To Mastery
              </p>
              <a
                href="https://www.udemy.com/certificate/UC-411b7c39-b7c2-4a64-92b9-eb8e8066e980/"
                target="_blank"
                rel="noreferrer"
                className="education-content-link"
                onMouseEnter={() => setCursor("certificate1")}
                onMouseLeave={() => setCursor("default")}
                onClick={() => setCursor("default")}
              >
                certificate
              </a>
            </div>
            <div
              className="education-content-text-row"
              data-scroll
              data-scroll-speed="1.1"
            >
              <p className="education-content-text1">
                ⚪️ Complete Webflow Bootcamp: From Figma Design to Development
              </p>
              <p className="education-content-text2">Udemy</p>
              <p className="education-content-text3">
                Instructors: Arash Ahadzadeh
              </p>
              <a
                href="https://www.udemy.com/certificate/UC-2953507a-64f8-4714-be56-a8915e37c667/"
                target="_blank"
                rel="noreferrer"
                className="education-content-link"
                onMouseEnter={() => setCursor("certificate2")}
                onMouseLeave={() => setCursor("default")}
                onClick={() => setCursor("default")}
              >
                certificate
              </a>
            </div>
            <div
              className="education-content-text-row"
              data-scroll
              data-scroll-speed="1"
            >
              <p className="education-content-text1">
                ⚪️ Responsive Web Design
              </p>
              <p className="education-content-text2">freeCodeCamp</p>
              <a
                href="https://www.freecodecamp.org/certification/farzinm4874/responsive-web-design"
                target="_blank"
                rel="noreferrer"
                className="education-content-link"
                onMouseEnter={() => setCursor("certificate3")}
                onMouseLeave={() => setCursor("default")}
                onClick={() => setCursor("default")}
              >
                certificate
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
