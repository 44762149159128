import React from 'react'
import './Loading.scss'

const Loading = () => {
  return (
    <section className='loading'>
        <div className='title1'>
            <p className='animate title1-text'>Hellooooo</p>
        </div>
        <div className='title2'>
            <p className='animate title2-text'>I am</p>
        </div>
        <div className='title3'>
            <p className='animate title3-text'>Farzin Mohamadi</p>
        </div>
        <div className='title4'>
            <p className='animate title4-text'>a</p>
        </div>
        <div className='title5'>
            <p className='animate title5-text'>Front-End developer</p>
        </div>
        <div className='title6'>
            <p className='animate title6-text'>&</p>
        </div>
        <div className='title7'>
            <p className='animate title7-text'>React developer</p>
        </div>
        <div className='title8'>
            <p className='animate title8-text'>I am</p>
        </div>
        <div className='title9'>
            <p className='animate title9-text'>So</p>
        </div>
        <div className='title10'>
            <p className='animate title10-text'>Happy</p>
        </div>
        <div className='title11'>
            <p className='animate title11-text'>You are Here</p>
        </div>
        <div className='title12'>
            <p className='animate title12-text'>Wellcome to my website</p>
        </div>
    </section>
  )
}

export default Loading