import React from 'react'
import './TextAnimation.scss'
const TextAnimation = () => {
  return (
    <div className='text-animation' >
        <div className='text-animation-container' data-scroll data-scroll-speed='-6' data-scroll-direction='horizontal'>
            <h3 className='text-animation-content1'>Farzin Mohamadi</h3>
            <h3 className='text-animation-content2'>Farzin Mohamadi</h3>
        </div>
    </div>
  )
}

export default TextAnimation