import React from "react";
import "./Tabs.scss";
import {Link} from 'react-router-dom'
function Tabs() {
  return (
    <section className="navigation" data-scroll data-scroll-speed='-.2'>
      <div className="navigation-container">
        <p>menu</p>
        <div className="menu-items">
          <Link to='/'>home</Link>
          <Link to='/about-me'>about</Link>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
