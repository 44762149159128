import React, { useEffect, useRef } from "react";
import IntroSection from "./section1/IntroSection";
import Ability from "./section2/Ability";
import Language from "./section3/Language";
import Education from "./section4/Education";
import Experience from "./section5/Experience";
import locomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";
import Footer from "../../components/footer/Footer";
import './AbouteRoute.scss'

const AboutMeRoute = () => {
  const scrollRef = useRef();
  useEffect(() => {
    const scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smartphone: { smooth: true },
      tablet: { smooth: true },
      lerp: 0.03,
      multiplier: 1.2,
    });
  });
  return (
    <div className="about-route-aa" ref={scrollRef}>
      <IntroSection />
      <Ability />
      <Language />
      <Education />
      <Experience />
      <Footer />
    </div>
  );
};

export default AboutMeRoute;
