import React from "react";
import "./Experience.scss";

const Experience = () => {
  return (
    <section className="experience">
      <div className="experience-container">
        <p
          className="experience-title"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="1"
        >
          Experience
        </p>
        <div className="experience-content">
          <div className="experience-content-container">
            <div
              className="experience-content-row"
              data-scroll
              data-scroll-speed="1"
            >
              <p className="experience-content-row1">
                1-PHYSICS TEACHER AND ACADEMIC COUNSELLOR
              </p>
              <p className="experience-content-row2">SALAM SCHOOLS COMPLEX</p>
              <p className="experience-content-row3">Sep 2009 - Sep 2018</p>
            </div>
            <div
              className="experience-content-row"
              data-scroll
              data-scroll-speed="1"
            >
              <p className="experience-content-row1">2-FRONT END DEVELOPER</p>
              <p className="experience-content-row2">WEB PORTAL COMPANY</p>
              <p className="experience-content-row3">Sep 2016 - Sep 2018</p>
            </div>
            <div
              className="experience-content-row"
              data-scroll
              data-scroll-speed="1"
            >
              <p className="experience-content-row1">3-FRONT END DEVELOPER</p>
              <p className="experience-content-row2">WEB NOVIN COMPANY</p>
              <p className="experience-content-row3">Oct 2018 - Dec 2020</p>
            </div>
            <div
              className="experience-content-row"
              data-scroll
              data-scroll-speed="1"
            >
              <p className="experience-content-row1">4- FRONT END DEVELOPER</p>
              <p className="experience-content-row2">FREELANCER</p>
              <p className="experience-content-row3">Dec 2020 - Present</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
