import "./App.scss";
import Main from "./components/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutMeRoute from "./routes/about-me-route/AboutMeRoute";
import Cursor from "./components/cursor/Cursor";
import Loading from "./components/loading/Loading";
import { useEffect, useState } from "react";

const App = () => {
  const [loading , setLoading] = useState(true)
  const [showing, setShowing] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowing(true), 23000);
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 24500);
  }, []);
  return (
    <div id="App" className="App">
      <Cursor />
      {loading && <Loading />}
      {showing && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about-me" element={<AboutMeRoute />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
