import React from "react";
import "./Home.scss";
import Label from "./label/Label";
import Logo from "./logo/Logo";
import Tabs from "./tabs/Tabs";
import TextAnimation from "./text-animation/TextAnimation";
const Home = () => {
  return (
    <section className="home">
      <Logo />
      <Tabs />
      <div className="home-container">
        <div className="home-title">
          <h1 data-scroll data-scroll-speed="2">
            Front-end & React
          </h1>
          <h2 data-scroll data-scroll-speed="1.8">
            Developer
          </h2>
        </div>
      </div>
      <Label />
      <TextAnimation />
    </section>
  );
};

export default Home;
