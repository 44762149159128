import { useContext, useEffect, useState } from "react";
import { CursorContext } from "../../context/cursorContext";
import "./Cursor.css";
const Cursor = () => {
  const { cursor } = useContext(CursorContext);
  const [mousePosition, setMpousePosition] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const mouseMove = (e) => {
      setMpousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    window.addEventListener("mousemove", mouseMove);

    return () => {
      document.removeEventListener("mousemove", mouseMove);
    };
  }, []);
  return (
    <>
      <div
        className={cursor}
        style={
          cursor === "default"
            ? {
                left: `${mousePosition.x - 25}px`,
                top: `${mousePosition.y - 25}px`,
              }
            : {
                left: `${mousePosition.x - 250}px`,
                top: `${mousePosition.y - 150}px`,
              }
        }
      ></div>
      <div
        className="cursor-dot"
        style={{
          left: `${mousePosition.x - 4}px`,
          top: `${mousePosition.y - 4}px`,
        }}
      ></div>
    </>
  );
};

export default Cursor;
