import React from "react";
import "./Ability.scss";

const Ability = () => {
  return (
    <section className="ability">
      <p
        className="ability-title"
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="-1"
      >
        Skills
      </p>
      <div className="ability-list">
        <div className="ability-list-container">
          <p className="ability-list-row" data-scroll data-scroll-speed="2">
            Html - Css - Sass
          </p>
          <p className="ability-list-row" data-scroll data-scroll-speed="2">
            javaScript - React.js - Next.js
          </p>
          <p className="ability-list-row" data-scroll data-scroll-speed="2">
            Redux - contextApi
          </p>
          <p className="ability-list-row" data-scroll data-scroll-speed="2">
            GraphQL - Gatsby.js
          </p>
          <p className="ability-list-row" data-scroll data-scroll-speed="2">
            Photoshop - Figma - Webflow
          </p>
          <p className="ability-list-row" data-scroll data-scroll-speed="2">
            AWS - fireBase - git
          </p>
        </div>
      </div>
    </section>
  );
};

export default Ability;
