import { createContext, useState } from "react";

export const CursorContext = createContext({
  page: 'default',
  setCursor: () => {},
});

export const CursorProvider = ({children}) => {
  const [cursor, setCursor] = useState('default');
  const value = { cursor, setCursor  };
  return <CursorContext.Provider value={value}>{children}</CursorContext.Provider>;
};
