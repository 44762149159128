import React from "react";
import Logo from "../../../components/home/logo/Logo";
import "./IntroSection.scss";
import Farzin from "../../../asset/Farzin.jpg";
import MagneticButton from "../../../components/magnetic-button/MagneticButton";
import Tabs from "../../../components/home/tabs/Tabs";
import CV from "../../../cv/Farzin-Mohamadi-Resume.pdf";

const IntroSection = () => {
  return (
    <div className="about-route">
      <Logo />
      <Tabs />
      <div className="about-route-container">
        <div className="about-route-content">
          <img
            className="about-route-image"
            src={Farzin}
            alt="farzin Mohamadi"
          />
          <div className="about-content-text-container">
            <p className="about-route-content-text">
              I am a creative, driven front-end web developer with a talent for
              quickly developing elegant solutions to problems. I have a drive
              and spirit for excellence. I am skilled in writing clean, testable
              code. Proficient in mobile-first responsive design and test-driven
              development.
            </p>
            <a
              className="btn-about-route"
              href={CV}
              download="Farzin-Mohamadi.pdf"
            >
              <MagneticButton
                className="button-1"
                style={{ backgroundColor: "transparent" }}
                scale={2}
                tollerance={0.8}
                speed={0.3}
                borderRadius="50%"
              >
                <MagneticButton
                  className="button-1"
                  scale={4}
                  tollerance={1}
                  speed={0.5}
                  borderRadius="50%"
                >
                  Download CV
                </MagneticButton>
              </MagneticButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
