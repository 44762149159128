import React from "react";
import "./Contact.scss";

const Contact = () => {
  return (
    <section className="contact">
      <div className="contact-container">
        <div className="contact-content">
          <a target='_blank' rel="noreferrer"  href="mailto:farzinmoha@gmail.com">farzinmoha@gmail.com</a>
          <a target='_blank' rel="noreferrer"  href="tel:+905528819132">+905528819132</a>
          <a target='_blank' rel="noreferrer"  href="https://www.linkedin.com/in/farzin-mohamadi/">LinkedIn</a>
          <a target='_blank' rel="noreferrer"  href="https://github.com/FarzinMoha">GitHub</a>
        </div>
        <p
          className="contact-title"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-1"
        >
          Contact
        </p>
      </div>
    </section>
  );
};

export default Contact;
