import React from "react";
import {Link} from 'react-router-dom'
import './Footer.scss'

function Footer() {
  return (
    <section className="footer">
      <Link to='/' className="footer-content">BY Farzin © 2022</Link>
    </section>
  );
}

export default Footer;
