import React from 'react'
import {Link} from 'react-router-dom'
import './Logo.scss'
const Logo = () => {
  return (
    <Link to='/' className='logo' data-scroll data-scroll-speed='-.2'>
        <span>Farzin Mohamadi</span>
    </Link>
  )
}
export default Logo