import React from "react";
import "./Label.scss";

const Label = () => {
  return (
    <div className="label" data-scroll data-scroll-speed='6'>
      <div className="label-container">
        <p className="label-text">Designer</p>
        <p className="label-text">Developer</p>
        <p className="label-text">Freelancer</p>
      </div>
    </div>
  );
};

export default Label;
